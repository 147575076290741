<template>
  <section id="our-coffee" class="d-flex flex-column flex-sm-row">
    <div class="left-wrap"></div>
    <div class="right-wrap d-flex align-end">
      <div class="right-wrap__block py-6">
        <h1 class="right-wrap__title mb-1">On Stage-Our Baoger-</h1>
        <h1 class="right-wrap__title mb-5">Oro Caffe Italian Style &lt;Espresso&gt;</h1>

        <h2 class="mb-3 font-1dot2r" style="color: #3c3c3c; font-style: italic">
          &ldquo;High quality freshly brewed coffee beans that brings you the best satisfaction, 100% natural roasted, without
          preservatives&#8221;
        </h2>
        <p>Our delicious mixture is an Espresso coffee from whole bean.</p>
        <p class="font-weight-bold mb-2">
          Mixing ratio: 50% Arabica beans / 50% Robusta beans/ a blend of Italian style coffee from Vietnam & Brazil.
        </p>

        <p>
          All the beans in the mixture are gently and slowly roasted one by one to preserve their characteristic. The coffee beans
          are quickly cooled with cold air instead of the usual water. Air cooling ensures that the beans retain their full aroma
          and do not absorb unnecessary moisture.
        </p>
        <p>Thanks to the high Robusta content of 50%, the Italian Style is a real crema miracle.</p>
        <p>
          The Italian Style reflects the Italian way of drinking espresso in the morning, after a meal or for a short break at the
          bar.
        </p>
        <p>Rating: Full-bodied coffee, excellent crema formation, slightly higher caffeine content.</p>
        <p class="font-weight-bold mb-2">
          Try our exceptional brewed Coffee, here at Baoger Lab & treat all your Senses with this coffee party.
        </p>
        <p>Follow us on our social media (Facebook and Instagram) for future News and Promotions.</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import '../../../styles/mixin.scss';
#our-coffee {
  .right-wrap {
    position: relative;
    width: 50%;
    @include for-sm {
      height: 100%;
      width: 100%;
    }
    &__title {
      font-size: 1.5rem;
    }
    &__block {
      min-height: 300px;
      width: 85%;
      margin: 0 auto;
      @include for-sm {
        width: 100%;
        padding: 20px;
      }
    }
  }
  .left-wrap {
    background-image: url('../../../assets/main-page/coffee.jpg');
    background-size: cover;
    background-position: center center;
    min-height: 600px;
    width: 50%;
    @include for-sm {
      min-height: 350px;
      width: 100%;
    }
  }
  p {
    line-height: 1.5;
  }
}
</style>
