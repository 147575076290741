<template>
  <div>
    <TopSection />
    <AboutUs />
    <OurIngredients />
    <OurMenu />
    <OurCoffee />
    <Location />
  </div>
</template>
<script>
import TopSection from './components/Top';
import AboutUs from './components/AboutUs';
import OurIngredients from './components/OurIngredients';
import OurMenu from './components/OurMenu';
import OurCoffee from './components/OurCoffee';
import Location from './components/Location';
export default {
  components: {
    TopSection,
    AboutUs,
    OurIngredients,
    OurMenu,
    OurCoffee,
    Location,
  }
}
</script>
