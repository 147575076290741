<template>
  <section id="our-menu" class="d-flex align-center px-4 py-8 py-sm-4">
    <div class="section-wrap text-center">
      <h1 class="section-title mb-6 cursor-pointer" @click="navigatePush('MenuAll')">{{ $t('mainMenu.lbl.ourMenu') }}</h1>
      <div v-for="(group, index) in sectionGroups" :key="index" class="section-group mb-8">
        <div class="d-flex">
          <v-divider class="section-group__divider mt-6 mb-6 mr-3"></v-divider>
          <div class="section-group__title mt-3">
            {{ group.title }}
          </div>
          <v-divider class="section-group__divider mt-6 mb-6 ml-3"></v-divider>
        </div>
        <div class="section-group__items-wrap">
          <div v-for="(item, itemIndex) in group.items" :key="itemIndex">
            <img class="section-group__items-img mb-2 cursor-pointer" :src="item.img" alt="item-img" @click="navigatePush('MenuAll')">
            <h4 class="white--text cursor-pointer" @click="navigatePush('MenuAll')">{{ item.name }}</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      sectionGroups: [
        {
          title: 'Savoury Bun',
          items: [
            { name: 'Grilled Chicken', img: require('@/assets/main-page/Grill-Chicken.png') },
            { name: 'Braised Pull Beef', img: require('@/assets/main-page/Braised-Pull-Beef.png') },
            { name: 'Smoked & Spicy Sausage', img: require('@/assets/main-page/Smoked-Spicy-Sausage.png') },
            { name: 'Grilled Chicken Sausage', img: require('@/assets/main-page/Grill-Chicken-Sausage.png') },
            { name: 'Grilled Lamb Sausage', img: require('@/assets/main-page/Grill-Lamb-Sausage.png') },
            { name: 'Double-Egg Omelette', img: require('@/assets/main-page/Double-Egg-Omelette.png') },
          ],
        },
        {
          title: 'Savoury Box',
          items: [
            { name: 'Grilled Chicken', img: require('@/assets/main-page/Box-Grill-Chicken.png') },
            { name: 'Braised Pull Beef', img: require('@/assets/main-page/Box-Braised-Pull-Beef.png') },
            { name: 'Smoked & Spicy Sausage', img: require('@/assets/main-page/Box-Smoked-Spicy-Sausage.png') },
            { name: 'Grilled Chicken Sausage', img: require('@/assets/main-page/Box-Grill-Chicken-Sausage.png') },
            { name: 'Grilled Lamb Sausage', img: require('@/assets/main-page/Box-Grill-Lamb-Sausage.png') },
            { name: 'Double-Egg Omelette', img: require('@/assets/main-page/Box-Double-Egg-Omelette.png') },
          ],
        },
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
@import '../../../styles/mixin.scss';
#our-menu {
  background-color: #231F20;
  height: 1100px;
  @include for-md {
    height: 100%;
  }

  .section-wrap {
    max-width: 90%;
    margin: 0 auto;

    .section-title {
      color: var(--primary-color);
      font-size: 2.5rem;
    }

    .section-group {
      &__title {
        color: var(--primary-color);
        font-size: 1.25rem;
      }
      &__divider {
        border-color: var(--primary-color);
        width: 400px;
      }
      &__items-wrap {
        display: grid;
        grid-gap: 1.2rem;
        grid-template-columns: repeat(3, 1fr);
        @include for-md {
          grid-template-columns: repeat(3, 1fr);
        }
        @include for-sm {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      &__items-img {
        max-width: 150px;
        @include for-sm {
          max-width: 120px;
        }
      }
    }
  }
}
</style>