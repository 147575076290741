<template>
  <section id="location" class="d-flex align-center">
    <div class="section-wrap text-center">
      <h1 class="section-title mb-6">{{ $t('mainMenu.lbl.location') }}</h1>
      <p class="mb-6">{{ $t('mainMenu.location.lbl.desc') }}</p>
      <div class="section-items-wrap">
        <img class="w-100" src="@/assets/main-page/location.png" alt="location">
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
    }
  },
}
</script>
<style lang="scss" scoped>
@import '../../../styles/mixin.scss';
#location {
  background-color: #fff;
  height: 600px;
  @include for-sm {
    height: 400px;
  }

  .section-wrap {
    max-width: 70%;
    margin: 0 auto;
    @include for-sm {
      max-width: 100%;
    }

    .section-title {
      font-size: 2.5rem;
    }

    .section-items-wrap {

    }
  }
}
</style>