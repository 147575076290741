<template>
  <section id="our-ingredients" class="d-flex align-center px-4 py-8 py-sm-4">
    <div class="section-wrap text-center">
      <h1 class="section-title mb-6">{{ $t('mainMenu.lbl.ourIngredients') }}</h1>
      <p>{{ $t('mainMenu.ourIngredients.lbl.desc') }}</p>
      <div class="section-items-wrap">
        <div v-for="(item, index) in ingredients" :key="index" class="section-item d-flex flex-column align-center py-8">
          <img class="section-item__img mb-2" :src="item.img" alt="item-img">
          <h3 class="mb-2">{{ item.title }}</h3>
          <p>{{ item.desc }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      ingredients: [
        { title: this.$t('mainMenu.ourIngredients.1.lbl.title') , desc: this.$t('mainMenu.ourIngredients.1.lbl.desc'), img: require('@/assets/main-page/vege.svg') },
        { title: this.$t('mainMenu.ourIngredients.2.lbl.title') , desc: this.$t('mainMenu.ourIngredients.2.lbl.desc'), img: require('@/assets/main-page/chicken.svg') },
        { title: this.$t('mainMenu.ourIngredients.3.lbl.title') , desc: this.$t('mainMenu.ourIngredients.3.lbl.desc'), img: require('@/assets/main-page/bao.svg') },
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
@import '../../../styles/mixin.scss';
#our-ingredients {
  background-color: #fff;
  height: 600px;
  @include for-sm {
    height: 100%;
  }

  .section-wrap {
    max-width: 75%;
    margin: 0 auto;

    .section-title {
      font-size: 2.5rem;
    }

    .section-items-wrap {
      display: grid;
      grid-gap: 1.2rem;
      grid-template-columns: repeat(3, 1fr);
      @include for-sm {
        grid-template-columns: repeat(1, 1fr);
      }
      .section-item {
        z-index: 1;
        position: relative;
        &__img {
          height: 75px;
          width: 75px;
        }
        &::before {
          z-index: -1;
          content: '';
          border: 6px solid var(--primary-color);
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
        }
        &::after {
          z-index: -1;
          content: '';
          position: absolute;
          background-color: #fff;
          top: 50px;
          left: 0px;
          right: -1px;
          bottom: 50px;
        }
      }
    }
  }
  p {
    line-height: 1.5;
  }
}
</style>